var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
  'message-input-toolbar',
  !_setup.isPC && 'message-input-toolbar-h5',
  _setup.isUniFrameWork && 'message-input-toolbar-uni',
]},[_c('div',{class:[
  'message-input-toolbar-list',
  !_setup.isPC && 'message-input-toolbar-h5-list',
  _setup.isUniFrameWork && 'message-input-toolbar-uni-list',
]},[(_setup.extensionListShowInStart[0])?[_c('div',{staticStyle:{"overflow":"hidden","width":"0","height":"0"}},_vm._l((_setup.extensionListShowInStart),function(extension){return _c(_setup.ToolbarItemContainer,{key:extension.id,attrs:{"iconFile":_setup.genExtensionIcon(extension),"title":_setup.genExtensionText(extension),"iconWidth":_setup.isUniFrameWork ? '25px' : '20px',"iconHeight":_setup.isUniFrameWork ? '25px' : '20px',"needDialog":false},on:{"onIconClick":function($event){return _setup.onExtensionClick(extension)}}})}),1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }