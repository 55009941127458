var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (typeof _setup.contactInfoData === 'object' && Object.keys(_setup.contactInfoData).length)?_c('div',{class:['tui-contact-info', !_setup.isPC && 'tui-contact-info-h5']},[(!_setup.isPC)?_c('div',{class:[
  'tui-contact-info-header',
  !_setup.isPC && 'tui-contact-info-h5-header',
]},[_c('div',{class:[
  'tui-contact-info-header-icon',
  !_setup.isPC && 'tui-contact-info-h5-header-icon',
],on:{"click":_setup.resetContactSearchingUIData}},[_c(_setup.Icon,{attrs:{"file":_setup.backSVG}})],1),_c('div',{class:[
  'tui-contact-info-header-title',
  !_setup.isPC && 'tui-contact-info-h5-header-title',
]},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIContact.添加好友/群聊"))+" ")])]):_vm._e(),_c('div',{class:['tui-contact-info-basic', !_setup.isPC && 'tui-contact-info-h5-basic']},[_c('div',{class:[
  'tui-contact-info-basic-text',
  !_setup.isPC && 'tui-contact-info-h5-basic-text',
]},[_c('div',{class:[
  'tui-contact-info-basic-text-name',
  !_setup.isPC && 'tui-contact-info-h5-basic-text-name',
]},[_vm._v(" "+_vm._s(_setup.generateContactInfoName(_setup.contactInfoData))+" ")]),_vm._l((_setup.contactInfoBasicList),function(item){return _c('div',{key:item.label,class:[
  'tui-contact-info-basic-text-other',
  !_setup.isPC && 'tui-contact-info-h5-basic-text-other',
]},[_vm._v(" "+_vm._s(`${_setup.TUITranslateService.t(`TUIContact.${item.label}`)}: ${item.data}`)+" ")])})],2),_c('img',{class:[
  'tui-contact-info-basic-avatar',
  !_setup.isPC && 'tui-contact-info-h5-basic-avatar',
],attrs:{"src":_setup.generateAvatar(_setup.contactInfoData)}})]),(_setup.contactInfoMoreList[0])?_c('div',{class:['tui-contact-info-more', !_setup.isPC && 'tui-contact-info-h5-more']},_vm._l((_setup.contactInfoMoreList),function(item){return _c('div',{key:item.key,class:[
  'tui-contact-info-more-item',
  !_setup.isPC && 'tui-contact-info-h5-more-item',
  item.labelPosition === _setup.CONTACT_INFO_LABEL_POSITION.TOP
    ? 'tui-contact-info-more-item-top'
    : 'tui-contact-info-more-item-left',
]},[_c('div',{class:[
  'tui-contact-info-more-item-label',
  !_setup.isPC && 'tui-contact-info-h5-more-item-label',
]},[_vm._v(" "+_vm._s(`${_setup.TUITranslateService.t(`TUIContact.${item.label}`)}`)+" ")]),_c('div',{class:[
  'tui-contact-info-more-item-content',
  !_setup.isPC && 'tui-contact-info-h5-more-item-content',
]},[(!item.editing)?_c('div',{class:[
  'tui-contact-info-more-item-content-text',
  !_setup.isPC && 'tui-contact-info-h5-more-item-content-text',
]},[_c('div',{class:[
  'tui-contact-info-more-item-content-text-data',
  !_setup.isPC && 'tui-contact-info-h5-more-item-content-text-data',
]},[_vm._v(" "+_vm._s(item.data)+" ")]),(item.editable)?_c('div',{class:[
  'tui-contact-info-more-item-content-text-icon',
  !_setup.isPC && 'tui-contact-info-h5-more-item-content-text-icon',
],on:{"click":function($event){return _setup.setEditing(item)}}},[_c(_setup.Icon,{attrs:{"file":_setup.editSVG,"width":"14px","height":"14px"}})],1):_vm._e()]):(item.editType === _setup.CONTACT_INFO_MORE_EDIT_TYPE.INPUT)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.data),expression:"item.data"}],class:[
  'tui-contact-info-more-item-content-input',
  !_setup.isPC && 'tui-contact-info-h5-more-item-content-input',
],attrs:{"type":"text"},domProps:{"value":(item.data)},on:{"confirm":function($event){return _setup.onContactInfoEmitSubmit(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.onContactInfoEmitSubmit(item)},"input":function($event){if($event.target.composing)return;_vm.$set(item, "data", $event.target.value)}}}):(item.editType === _setup.CONTACT_INFO_MORE_EDIT_TYPE.TEXTAREA)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.data),expression:"item.data"}],class:[
  'tui-contact-info-more-item-content-textarea',
  !_setup.isPC && 'tui-contact-info-h5-more-item-content-textarea',
],attrs:{"confirm-type":"done"},domProps:{"value":(item.data)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "data", $event.target.value)}}}):(item.editType === _setup.CONTACT_INFO_MORE_EDIT_TYPE.SWITCH)?_c('div',{on:{"click":function($event){return _setup.onContactInfoEmitSubmit(item)}}},[_c(_setup.SwitchBar,{attrs:{"value":item.data}})],1):_vm._e()])])}),0):_vm._e(),_c('div',{class:[
  'tui-contact-info-button',
  !_setup.isPC && 'tui-contact-info-h5-button',
]},_vm._l((_setup.contactInfoButtonList),function(item){return _c('button',{key:item.key,class:[
  'tui-contact-info-button-item',
  !_setup.isPC && 'tui-contact-info-h5-button-item',
  item.type === _setup.CONTACT_INFO_BUTTON_TYPE.CANCEL
    ? `tui-contact-info-button-item-cancel`
    : `tui-contact-info-button-item-submit`,
],on:{"click":function($event){return _setup.onContactInfoButtonClicked(item)}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t(`TUIContact.${item.label}`))+" ")])}),0),_c(_setup.Drag,{ref:"dragRef",attrs:{"show":_setup.isCalling,"domClassName":"callkit-drag-container"}},[_c(_setup.TUICallKit,{class:[
  'callkit-drag-container',
  `callkit-drag-container-${_setup.isMinimized ? 'mini' : 'pc'}`,
],attrs:{"allowedMinimized":true,"allowedFullScreen":false,"beforeCalling":_setup.beforeCalling,"afterCalling":_setup.afterCalling,"onMinimized":_setup.onMinimized}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }