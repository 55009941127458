var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isOverlayShow)?_c('div',{ref:"overlayDomRef",staticClass:"overlay",style:({
    position: _setup.props.isFullScreen ? 'fixed' : 'absolute',
    zIndex: _setup.props.zIndex,
  }),on:{"click":_setup.onOverlayClick}},[(_setup.props.useMask)?_c('div',{class:{
      'overlay-mask': true,
      'fade-in': _setup.props.visible,
    },style:({
      backgroundColor: _setup.props.maskColor,
    })}):_vm._e(),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("default")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }